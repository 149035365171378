<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template #cell(partner_name_en)="data">
        <b-media vertical-align="center">
          <template
            v-if="data.item.flag"
            #aside
          >
            <b-avatar
              size="32"
              :src="data.item.flag"
              :text="data.item.partner_name_en"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.partner_name_en }}
          </b-link>
        </b-media>
      </template>
      <template #cell(blocked)="data">
        {{ changeVaraint(data.item.blocked) }}
      </template>
      <template #cell(map)="data">
        <a
          target="_blank"
          :href="'https://www.google.com/maps/search/?api=1&query='+data.item.latitude+','+data.item.longitude"
          class="map-btn"
        > Show Map </a>

      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/partners',
      addType: 'Add Partner',
      addComponentName: 'add-partner',
      editComponent: 'edit-partner',
      viewContent: true,
      type: 'page',
      columns: [
        { key: 'id', sortable: true },
        { key: 'image', label: 'Image' },
        { key: 'name', label: 'Partner' },
        { key: 'email', label: 'Email' },
        { key: 'address', label: 'Address' },
        { key: 'map', label: 'Map' },
        { key: 'blocked', label: 'Status' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == '0') return 'Active'

      return 'Blocked'
    },
  },
}
</script>

<style>

</style>
